import React, { useContext } from 'react'
import { UserContext } from '@/App'
import { tickethelper } from '@/_helpers'
import { Card, InfoBox, Table, Pagination, NoContentBox, useGet } from '@enkla-gui'
import Greeting from '@components/layout/Greeting'
import Calendar from './calendar'

import { icons } from '@config'
export default function Dashboard() {
	const { billable } = useContext(UserContext)
	const { data: projected } = useGet({ endpoint: 'api/economy/projected' })
	const { data: tickets, setPage } = useGet({ endpoint: 'api/ticket', filter: { status: 'new' } })
	const { data: tasks } = useGet({ endpoint: 'api/task' })

	return (
		<>
			<Greeting />

			<div class="row">
				<div class="col-3">
					<InfoBox
						link={{ href: '#!/entity/type/vacancies', name: 'Visa' }}
						loading={!billable}
						title='Vakanser'
						subtitle={billable.stat_vacancies}
						icon={icons.vacancies}
					/>
					<InfoBox
						link={{ href: '#!/lease/filter/ending', name: 'Visa' }}
						loading={!billable}
						title='Upphörande denna månad'
						subtitle={billable.stat_ends_month}
						icon={icons.clock}
					/>
					<InfoBox
						link={{ href: '#!/invoicebase/status/review', name: 'Visa' }}
						loading={!billable}
						title='Attestering'
						subtitle={billable?.stat_not_sent_invoices}
						icon={icons.inbox}
					/>
					<InfoBox
						link={{ href: '#!/invoicebase/status/overdue', name: 'Visa' }}
						loading={!billable}
						title='Förfallna avier'
						subtitle={billable?.stat_overdue_invoices}
						icon={icons.warning}
					/>
				</div>
				<div class="col-9">
					<Card title='Nya ärenden' icon={icons.ticket}>
						<Table
							rows={tickets?.data}
							empty={{ text: 'Inga nyinkomna ärenden', icon: icons.ticket }}
							link={`/#!/ticket/@{id}`}
							functions={{
								getStatus: status => <span class={`badge w-100 bg-${tickethelper.status(status).color}`}>{tickethelper.status(status).label}</span>,
								getType: type => <span>{tickethelper.type(type)}</span>,
								tenant: ticket =>
									ticket.tenant ? (
										<a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/tenant/${ticket.tenant.id}/`}>
											{' '}
											{ticket.tenant.name}
										</a>
									) : (
										''
									)
							}}
							keys={['status>function:getStatus', 'type>function:getType', 'subject', '_this>function:tenant']}>
							<th class='min'>Status</th>
							<th class='min'>Ärendetyp</th>
							<th>Ämne</th>
							<th class='min'>Hyresgäst</th>
						</Table>
						<Pagination data={tickets} fetch={setPage} />
					</Card>
					<Calendar />
				</div>
			</div>
		</>
	)
}
