import React, { useState } from 'react'
import { API, SearchModal, ViewHeader, Card, Pagination, Table, useGet, Filter, Help } from '@enkla-gui'
import { icons, filters } from '@config'
import { maintenancehelper } from '@/_helpers'
export default function MaintenanceOverview() {

	const { data: activities, setSorting, sorting, setPage, loading, setFilter } = useGet({ endpoint: `api/maintenance`, filter: { limit: 15 } })
	const [searchModal, setSearchModal] = useState(false)
	return (
		<>
			<ViewHeader title='Förvaltningsaktiviteter' subtitle='Översikt' icon={icons.maintenance} buttons={[
				{ icon: icons.create, title: 'Skapa aktivitet', callback: () => setSearchModal(true) },
			]} />
			<Filter filters={filters.maintenance} useFilter={_filter => setFilter({ ..._filter })} />
			<Card>
				<Table
					rows={activities?.data}
					link={`/#!/maintenance/@{id}`}
					fetching={loading}
					sorting={sorting}
					empty={{
						text: 'Det finns inga aktiviteter',
						icon: icons.maintenance
					}}
					functions={{
						linked: linked => (
							<a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/${linked.type}/${linked.id}/`}>
								{linked.name}
							</a>
						),
						status: status => maintenancehelper.statusBadge(status)
					}}
					keys={['title', 'linked>function:linked', 'status>function:status', 'completion', 'created_at>date:short']}
					useSorting={sort => setSorting({ ...sort })}
					headers={[
						{
							value: 'Aktivitet',
							sortable: true,
							key: 'title'
						},
						{
							value: 'Avser objekt/fastighet',
							key: 'linked'
						},
						{
							value: 'Status',
							sortable: true,
							class: 'min',
							key: 'status'
						},

						{
							value: 'Avklarade uppgifter',
							class: 'min',
							key: 'completion'
						},
						{
							key: 'created_at',
							value: 'Skapad',
							sortable: true,
							class: 'min'
						}
					]}
				/>
				<Pagination data={activities} fetch={setPage} />
			</Card>
			<SearchModal
				search={async query =>
					await API.get('api/search', [
						['query', query],
						['size', 5],
						['index', 'entities,companies']
					])
				}
				open={searchModal}
				close={() => setSearchModal(false)}
				callback={result => window.location.hash = `#/${result._index === 'entities' ? 'entity' : 'company'}/${result._source.render.id}/maintenance/create`}
			>
				<Help title='Information'>
					En förvaltningsaktivitet behöver vara kopplad till ett <b>objekt</b> eller ett <b>företag.</b>
					<br />
					Sök fram det objekt eller företag du vill skapa förvaltningsaktiviteten på.
				</Help>
			</SearchModal>
		</>
	)
}
