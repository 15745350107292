import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, InfoBox, ViewHeader, Submit, Input, TextArea, useGet, useSubmit } from '@enkla-gui'
import { icons } from '@config'
export default function Contact() {
  const { type, id } = useParams()
  const { data: recipients } = useGet({ endpoint: `api/contact/recipients/${type}/${id}` })
  const { register, submit, loading, error, setValue } = useSubmit({ endpoint: `api/contact/${type}/${id}`, success: () => successCallback() })

  const successCallback = () => {
    setValue('subject', '')
    setValue('body', '')
  }

  return (
    <>
      <ViewHeader loading={!recipients} title='Kontakta' icon={icons.email} subtitle={recipients?.target} />
      <div class='row'>
        <Card
          loading={!recipients}
          cols='col-8'
          buttons={{
            footer: [<Submit onSubmit={submit} text='Skicka' sending={loading} />]
          }}>
          <Input controls={{ register }} name='subject' options={{ required: true }} label='Ämnesrad' error={error} />
          <TextArea controls={{ register }} name='body' options={{ required: true }} label='Beskrivning' error={error} />
        </Card>
        <div class='col-4'>
          <InfoBox loading={!recipients} title='Berörda hyresgäster' subtitle={recipients?.count} icon={icons.tenant} border='border-light' />
          <Card title='Tips!' icon={icons.info}>
            <p>Använd [namn] i ämnesraden eller innehållet för att skriva ut hyresgästens namn.</p>
          </Card>
        </div>
      </div>
    </>
  )
}
