import { API, Button, Card, NoContentBox, Output, ViewHeader } from '@enkla-gui'
import React, { useEffect, useState } from 'react'
import { date, notify, tickethelper } from '@/_helpers'
import { useHistory, useParams } from 'react-router-dom'

import { billable } from '@/_config'
import { useForm } from 'react-hook-form'

export default function Ticket() {
  const { register, handleSubmit, reset } = useForm()
  const [error, setError] = useState(null)
  const [ticket, setTicket] = useState(null)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    getTicket()
  }, [id])

  const getTicket = () => {
    API.get(`api/ticket/${id}`).then(ticket => setTicket(ticket))
  }

  const resolve = () => {
    API.post(`api/ticket/${id}/resolve`)
      .then(res => {
        notify.toast('Ärendet har marketats som löst')
        getTicket()
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  const open = () => {
    API.post(`api/ticket/${id}/open`)
      .then(res => {
        notify.toast('Ärendet har öppnats')
        getTicket()
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  const comment = data => {
    API.post('api/ticket/comment', data)
      .then(res => {
        notify.toast('Kommentaren har skapats')
        reset()
        getTicket()
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  const remove = async () => {
    if (!(await notify.confirm('Ärendet kommer även att raderas för hyresgästen'))) return
    API.delete(`api/ticket/${id}`)
      .then(res => {
        notify.toast(`Ärendet har raderats`)
        history.push('/ticket/overview')
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  return (
    <>
      <ViewHeader
        loading={!ticket}
        title={`Ärende · ${ticket?.reference}`}
        subtitle={tickethelper.type(ticket?.type)}
        icon='fa-envelope-open-text'
        breadcrumbs={ticket?.breadcrumb}
        buttons={[
          { icon: 'fa-trash-alt', title: 'Radera', callback: () => remove(), position: 'right' },
          { icon: 'fa-edit', title: 'Redigera', href: `#!/ticket/${ticket?.id}/edit`, position: 'right' }
        ]}
        badges={[{ bg: tickethelper.status(ticket?.status)?.color, text: tickethelper.status(ticket?.status)?.label }]}
      />
      <Card
        loading={!ticket}
        title='Ärende'
        icon='fas fa-envelope-open-text'
        buttons={{
          footer: [
            { hidden: ticket?.status !== 'OPEN', callback: () => resolve(), bg: 'light', text: 'Markera som löst' },
            { hidden: ticket?.status === 'OPEN', callback: () => open(), bg: 'warning', text: 'Markera som pågående' }
          ]
        }}>
        <div class='row mb-3'>
          <Output value={ticket?.subject} label='Ämne' />
          <Output value={tickethelper.type(ticket?.type)} label='Ärendetyp' />
        </div>
        <div class='row mb-3'>
          <Button label='Objekt' hidden={!ticket?.entity?.name} value={ticket?.entity?.name} link={`#!/entity/${ticket?.entity?.id}`} />
          <Button
            label='Hyresgäst'
            hidden={!ticket?.tenant?.name}
            value={`${ticket?.tenant?.name} · ${ticket?.tenant?.email} · ${ticket?.tenant?.phone}`}
            link={`#!/tenant/${ticket?.tenant?.id}`}
          />
        </div>
        <div class='row mb-3'>
          <Output value={date.full(ticket?.created_at)} label='Skapad' />
          <Output value={date.full(ticket?.updated_at)} label='Uppdaterad' />
        </div>
        <div class='row'>
          <div class='col'>
            <div class='form-group'>
              <textarea
                rows={10}
                disabled
                type='text'
                style={{ background: 'rgb(248, 248, 251)' }}
                class='form-control form-control-input '
                value={ticket?.description}
              />
              <label class='label-control'>Beskrivning</label>
            </div>
          </div>
        </div>
      </Card>
      <Card loading={!ticket} title='Kommentarer' icon='fas fa-comments' _class='ticket-comments-card'>
        <form onSubmit={handleSubmit(comment)}>
          <input type='hidden' {...register('ticket_id')} value={id} />
          <div class='row'>
            <div class='col'>
              <div class='ticket-comments-window'>
                {ticket?.comments &&
                  ticket?.comments.map(comment => {
                    if (comment.author === 'SYSTEM') {
                      return (
                        <div class='ticket-comment ticket-system-comment'>
                          <span class='pull-right'>
                            <b>{date.full(comment.created_at)}</b>
                          </span>
                          <span class='comment'>
                            <i class='fas fa-info-circle' /> <b>Händelse:</b> {comment.comment}
                          </span>
                        </div>
                      )
                    } else {
                      return (
                        <div class='ticket-comment'>
                          <div class='col-md-12'>
                            <span class='author'>
                              {comment.author === 'TENANT' ? ticket?.tenant.name : billable.name} <b>{date.since(comment.created_at)}</b>
                            </span>
                            <span class='pull-right mb-5'>
                              <b>{date.full(comment.created_at)}</b>
                            </span>
                          </div>
                          <span class='comment'>{comment.comment}</span>
                        </div>
                      )
                    }
                  })}
                {ticket?.comments && ticket?.comments?.length === 0 && <NoContentBox text='Det finns inte några kommentarer' icon='fa-comment-slash' />}
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col mb-3'>
              <div class='form-group ticket-comments-form'>
                <label>Meddelande</label>
                <textarea disabled={ticket?.status !== 'OPEN'} rows='4' {...register('comment', { required: true })} type='text' class='form-control' />
                {error?.data?.comment && (
                  <div class='form-inline-error'>
                    <i class='fa fa-fw fa-exclamation-triangle' /> {error.data.comment}{' '}
                  </div>
                )}
                <button disabled={ticket?.status !== 'OPEN'} class='btn btn-primary text-light' type='submit'>
                  {ticket?.status === 'OPEN' ? 'Kommentera' : 'Markera ärendet som pågående för att kommentera'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </>
  )
}
