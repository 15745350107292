import { Card, InfoBox, Input, Pagination, Submit, Table, ViewHeader, date, useDelete, useGet, usePost, useSubmit } from '@enkla-gui'
import { useHistory, useParams } from 'react-router-dom'

import Invoices from '@components/tenant/Invoices'
import Modal from 'react-modal'
import React from 'react'
import { icons } from '@config'
import { tickethelper } from '@/_helpers'
export default function Tenant() {
	let { id } = useParams()
	const history = useHistory()

	const { data: tenant, fetch: fetchTenant } = useGet({ endpoint: `api/tenant/${id}` })
	const { data: config } = useGet({ endpoint: `api/tenantportalconfig` })
	const { data: tickets, fetch: fetchTickets } = useGet({ endpoint: `api/tenant/${id}/tickets` })

	const { post: resend, loading } = usePost()
	const { post: makePrimary } = usePost({
		confirm: 'Detta kommer ändra den nuvarande primära adressen till faktureringsadress'
	})

	console.log(tenant);

	const {
		submit,
		register,
		reset,
		getValues,
		modal,
		setModal,
		error,
		loading: updatingAddress
	} = useSubmit({ endpoint: 'api/address', success: fetchTenant, method: 'patch' })

	const { remove } = useDelete({ endpoint: `api/tenant/${id}`, success: () => history.push('/tenant/overview') })
	const { remove: removeAddress } = useDelete({ endpoint: 'api/address/', success: fetchTenant })

	return (
		<>
			<ViewHeader
				loading={!tenant}
				icon={icons.tenant}
				title={tenant?.name}
				subtitle={tenant?.identifier}
				breadcrumbs={tenant?.breadcrumb}
				buttons={[
					{
						loading: loading,
						icon: icons.send,
						title: 'Skicka inbjudan till hyresgästportal',
						tip: !config?.enabled ? 'Aktivera hyresgästportalen för att kunna skicka en inbjudan' : false,
						disabled: !config?.enabled,
						hidden: tenant?.invite === null,
						callback: () => resend({ endpoint: `api/tenant/${id}/resend` })
					},
					{
						icon: icons.email,
						title: 'Kontakta hyresgäst',
						href: `#!/contact/tenant/${tenant?.id}`
					},
					{
						icon: icons.remove,
						title: 'Radera',
						callback: () => remove(),
						position: 'right'
					},
					{
						icon: icons.edit,
						title: 'Redigera',
						href: `#!/tenant/${tenant?.id}/edit`,
						position: 'right'
					}
				]}
				badges={[
					{
						bg: 'warning',
						text: `Inbjudan skickad till hyresgästportalen (${date.short(tenant?.invite_sent_at)})`,
						hidden: !config?.enabled || tenant?.invite === null || tenant?.invite_sent_at === null
					},
					{ bg: 'secondary', text: 'Företagskund', hidden: !tenant?.organisation }
				]}
			/>
			<div class='row'>
				<div class='col'>
					<InfoBox loading={!tenant} title='E-post' subtitle={tenant?.email} icon={icons.email} />
				</div>
				<div class='col'>
					<InfoBox loading={!tenant} title='Telefon' subtitle={tenant?.phone} icon={icons.phone} />
				</div>
			</div>
			<Card title='Uthyrningar' icon={icons.lease}>
				<Table
					rows={tenant?.leases}
					link={`/#!/lease/@{id}`}
					functions={{
						active: lease => <span class={`badge bg-${lease.state.color} w-100`}>{lease.state.title}</span>,
						entity: entity => (
							<a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/entity/${entity.id}/`}>
								{entity.name}
							</a>
						),
						getType: type => (
							<div class='icon-badge'>
								<i class={`fas fa-fw ${type.icon}`} /> <span>{type.name}</span>{' '}
							</div>
						)
					}}
					keys={['entity.type>function:getType', '_this>function:active', 'start>date:short', 'stop>date:short', 'entity>function:entity']}>
					<th class='min'>Typ</th>
					<th class='min'>Status</th>
					<th>Tillträde</th>
					<th>Slutdatum</th>
					<th class='min'>Objekt</th>
				</Table>
			</Card>
			<Invoices id={tenant?.id} />
			<Card
				title='Adresser'
				icon={icons.address}
				buttons={{
					header: [
						{
							link: `#!/address/create/tenant/${tenant?.id}`,
							icon: icons.create,
							text: 'Skapa'
						}
					]
				}}>
				<Table
					rows={tenant?.addresses}
					functions={{
						getType: type => <span class='badge bg-light'>{type === 'PRIMARY' ? 'Primär' : 'Fakturering'}</span>,
						remove: address => removeAddress(address.id),
						edit: address => {
							reset(address)
							setModal(true)
						}
					}}
					keys={[
						`_this>button:edit:${icons.edit}`,
						'address',
						'postal_code',
						'city',
						'country',
						'type>function:getType',
						'created_at>date:short',
						`_this>button:remove:${icons.remove}`
					]}>
					<th class='min' />
					<th>Adress</th>
					<th>Postnummer</th>
					<th>Stad</th>
					<th>Land</th>
					<th>Typ</th>
					<th>Skapad</th>
					<th />
				</Table>
			</Card>

			<Card
				title='Ärenden'
				icon={icons.ticket}
				buttons={{
					header: [
						{
							link: `#!/ticket/create/tenant/${id}`,
							icon: icons.create,
							text: 'Skapa'
						}
					]
				}}>
				<Table
					rows={tickets?.data}
					link={`/#!/ticket/@{id}`}
					empty={{
						text: 'Det finns inte några ärenden',
						icon: icons.ticket
					}}
					functions={{
						getStatus: status => <span class={`badge w-100 bg-${tickethelper.status(status).color}`}>{tickethelper.status(status).label}</span>,
						entity: data => (
							<a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/entity/${data.entity.id}/`}>
								{' '}
								{data.entity.name}
							</a>
						),
						getType: type => <span>{tickethelper.type(type)}</span>
					}}
					keys={['status>function:getStatus', 'reference', 'type>function:getType', 'subject', 'created_at>date:full', '_this>function:entity']}>
					<th class='min'>Status</th>
					<th class='min'>Ärendenummer</th>
					<th class='min'>Ärendetyp</th>
					<th>Ämne</th>
					<th class='min'>Skapat</th>
					<th class='min'>Objekt</th>
				</Table>
				<Pagination data={tickets} fetch={fetchTickets} />
			</Card>
			<Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
				<Card
					title='Redigera Adress'
					icon={icons.edit}
					buttons={{
						header: [
							{
								callback: () => {
									reset({})
									setModal(false)
								},
								icon: icons.cancel,
								text: 'Avbryt',
								bg: 'light'
							}
						],
						footer: [
							<Submit onSubmit={submit} text='Uppdatera' sending={updatingAddress} />,
							<button hidden={getValues('type') === 'PRIMARY'} class='btn btn-light' onClick={() => makePrimary({ endpoint: `api/address/${id}/primary` })}>
								Gör till primär
							</button>
						]
					}}>
					<div class='row mb-3'>
						<Input controls={{ register }} name='address' options={{ required: true }} label='Adress' error={error} />
					</div>
					<div class='row mb-3'>
						<Input controls={{ register }} name='postal_code' options={{ required: true }} label='Postnummer' error={error} />
					</div>
					<div class='row mb-3'>
						<Input controls={{ register }} name='city' options={{ required: true }} label='Stad' error={error} />
					</div>
					<div class='row mb-3'>
						<Input controls={{ register }} name='country' options={{ required: true }} label='Land' error={error} />
					</div>
				</Card>
			</Modal>
		</>
	)
}
