import { Card, Date, Help, Input, Select, Submit, TextArea, ViewHeader, useGet, useSubmit } from '@enkla-gui'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { BehaviorSubject } from 'rxjs'
import MaintenanceTasks from '@components/maintenance/MaintenanceTasks'
import { icons } from '@config'

export default function MaintenanceCreate() {
	const history = useHistory()
	let { type, id } = useParams()
	const { data: templates } = useGet({ endpoint: 'api/maintenance/template' })
	const { data: parent } = useGet({ endpoint: `api/${type}/${id}` })
	const [tasks, setTasks] = useState(new BehaviorSubject([]))
	const { register, error, loading, submit, setValue, control, watch } = useSubmit({
		form: { template_id: null },
		endpoint: 'api/maintenance',
		success: res => history.push(`/maintenance/${res.maintenance.id}`)
	})

	const selectTemplate = value => {
		if (value === '') value = null
		setValue('template_id', value)
		if (value !== null) {
			var template = templates.data.filter(t => t.id === value)[0]
			setTasks(new BehaviorSubject(template.tasks))
			setValue('title', template.title)
			setValue('description', template.description)
			setValue('reschedule_in', template.reschedule_in)
		}
	}

	return (
		<>
			<ViewHeader
				title='Förvaltningsaktivitet'
				icon={icons.maintenance}
				subtitle='Skapa'
				breadcrumbs={parent?.breadcrumb}
			/>
			<div class='row'>
				{/* det här är rätt crazy men skrillex bryr sig inte */}
				<input type='hidden' {...register(`${type}_id`)} value={id} />
				<Card>
					<div class='row  mb-3'>
						{templates && (
							<Select
								clearable
								onChange={selectTemplate}
								controls={{ register, setValue }}
								name='template_id'
								label='Rutinmall'
								values={templates?.data.map(template => {
									return { key: template.title, value: template.id }
								})}
							/>
						)}
					</div>
					<div class='row mb-3'>
						<div class='col'>
							<Input disabled={watch('template_id') !== null} controls={{ register }} name='title' options={{ required: true }} label='Namn' error={error} />
							<TextArea
								disabled={watch('template_id') !== null}
								controls={{ register }}
								name='description'
								options={{ required: true }}
								label='Beskrivning'
								error={error}
							/>{' '}
						</div>
					</div>
					<div class='row mb-3'>
						<div class='col'>
							<Date disabled={watch('template_id') !== null} label='Schemalagt datum' controls={{ control }} name='scheduled_at' error={error} />
						</div>
						<div class='col'>
							<Input
								disabled={watch('template_id') !== null}
								controls={{ register }}
								type='number'
								name='reschedule_in'
								options={{ required: false }}
								label='Återkommande efter antal dagar'
								error={error}
							/>
						</div>
					</div>
				</Card>
			</div>
			<div class='row'>
				<Card>
					<MaintenanceTasks previewMode={watch('template_id') !== null} tasks={tasks} />
				</Card>
			</div>
			<div class='d-grid gap-2'>
				{watch('template_id') !== null && (
					<Help temporary header={false} type='info'>
						<p>Aktiviteten är kopplad till en rutin och går därför inte att ändra</p>
					</Help>
				)}
				<Submit
					onSubmit={() => {
						setValue('tasks', tasks.getValue())
						submit()
					}}
					text='Skapa rutin'
					sending={loading}
				/>
			</div>
		</>
	)
}
