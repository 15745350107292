import { CardLoader } from '@enkla-gui'
import React from 'react'

export default function Card({ title, buttons, children, _class = '', icon, cols = 'col', loading = false }) {
  return (
    <div class={`component_Card ${cols}`}>
      <div class={`card ${_class}`}>
        {title && (
          <div class='card-header'>
            <div class='card-header-text'>
              <i class={`fa-fw ${icon}`} />
              <span>{title}</span>
            </div>
            <div class='card-header-buttons'>
              {buttons?.header?.map(button => {
                if (button.hidden) return null
                if (loading) return null
                if (React.isValidElement(button)) return button
                return (
                  <a class={`btn btn-sm ml-5 btn-${button.bg ? button.bg : 'primary text-light'}`} href={button.link} onClick={button.callback}>
                    <i class={button.icon} /> {button.text}
                  </a>
                )
              })}
            </div>
          </div>
        )}
        <div class='card-body'>
          {!loading && children}
          {loading && <CardLoader />}
        </div>
        <div class='card-buttons'>
          {buttons?.footer?.map(button => {
            if (button.hidden) return null
            if (loading) return null
            if (React.isValidElement(button)) return button
            return (
              <button class={`btn btn-${button.bg ? button.bg : 'primary text-light'}`} href={button.link} onClick={() => button.callback && button.callback()}>
                {button.text}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
