import React from 'react'
import { Explination } from '@enkla-gui'
export default function Output({ label, value, type = 'text', cols = 'col', background = '#ebebeb', hidden = false, tip = false, currency = 'SEK' }) {
	switch (type) {
		case 'currency':
			value = new Intl.NumberFormat('sv-SE', {
				style: 'currency',
				currency: currency,
				maximumFractionDigits: 0
			}).format(parseInt(value))
			break
		default:
			break
	}
	if (hidden) return null
	return (
		<div class={`component_Output ${cols}`}>
			<div class='form-group'>
				<input style={{ background: background }} value={value || ''} disabled class='form-control-input form-control' />
				<label class='label-control text'>
					{label} {tip && <Explination tip={tip} />}
				</label>
			</div>
		</div>
	)
}
